@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700&display=swap");
@import "./apps/Analytics/analytics.scss";
@import "@arcgis/core/assets/esri/themes/light/main.css";

body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", Calibri, Candara, Arial, sans-serif;
  font-size: 16px;
}

/* root styles */
:root {
  font-size: 16px;
}

#root {
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.dropdown-menu {
  border-radius: 0;
  border-bottom: 2px solid #ce2c09;
}

// a11y to highlight focused links
a:focus-visible {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
// end
.main {
  flex-grow: 1;
}

.main:has(.district-report-container) {
  overflow: auto;
}
.form-control,
.form-select {
  border-radius: 0;
}

.btn {
  border-radius: 0;
}
.navbar-brand {
  white-space: normal;
}
.navbar-nav .nav-link,
.navbar-nav .nav-item:last-of-type,
.nav-tabs .nav-link {
  font-weight: 600;
}
// CARDS
.card {
  border: 0 none;
}
.custom-card .card {
  background-color: #0a5494;
  border-radius: 0;
}
.custom-card .card a:link,
.custom-card .card a:visited {
  color: #ffffff;
  text-decoration: none;
  // TODO: is this bg-color needed? adding it caused style issue
  // on the typeahead dropdown
  // background-color: #0a5494;
}
.custom-card .card h5 {
  font-size: 1rem;
  color: #202020;
}

.btn-filter {
  border-radius: 0;
  font-size: 1.1rem;
  background-color: #4e8c62;
  border-color: #4e8c62;
  color: #fff;
  padding: 0.375rem 1.25rem;
  margin: 0.75rem 0;

  &:hover,
  &:focus {
    background-color: #3c6b4b;
    border-color: #3c6b4b;
    color: #fff;
  }
}

.alert {
  padding: 1rem;
  margin-bottom: 0;
  border-radius: 0;
}
.alert-danger {
  border-bottom: 1px solid #505050;
}

/* modal*/
.modal-header {
  background-color: #0a5494;
  border-bottom: none;
  color: #fff;
}
.modal-header .btn-close {
  color: rgba(255, 255, 255, 0.7);
}
.modal-title {
  align-self: center;
}

.modal-body {
  padding: 1rem 0 0;
  background-color: #f3f3f3;
}

.navbar-toggler {
  border-radius: 0;
}

.esri-print__advanced-options-section,
.esri-print__template-button {
  display: none !important;
}

/* Media Queries */

@media screen and (max-width: 992px) {
  // increase layers list font size on smaller screens
  .esri-widget {
    font-size: 16px;
  }
  .esri-layer-list__item--has-children > .esri-layer-list__list {
    font-size: 14px;
  }
}

@media screen and (min-width: 992px) {
  .main .card h5 {
    font-size: 1.25rem;
  }
}

@media screen and (min-width: 1200px) {
  .navbar-nav .nav-link,
  .navbar-nav .nav-item:last-of-type {
    font-size: 1.2rem;
  }
}
.esri-layer-list__updating {
  background-color: #fff !important;
  // animation: updating 2s normal infinite;
}

.esri-widget {
  // increase font size for small screens
  // font-size: 16px;
  // specifying allowedFormats on PrintWidget
  // does not allow for keeping TIFF and AIX formats
  // (tested esri 4.26)
  /*
   allowedFormats: [
              "pdf",
              "png32",
              "png8",
              "jpg",
              "gif",
              "eps",
              "svg",
              "aix", <-- does not work
              "tiff", <-- does not work
            ]
  */
  select {
    option[value="svgz"] {
      display: none;
    }
  }
}

// custom popup template
.find-my-location-template {
  .esri-icon-directions2 {
    font-size: 24px;
  }
  .item {
    display: flex;
    margin-bottom: 0.25rem;
  }
  dt {
    margin-right: 0.25rem;
  }
  dd {
    margin: 0;
  }
}

.material-icons-outlined {
  font-size: 1.5rem;
  &.sm {
    font-size: 1.25rem;
  }
  &.lg {
    font-size: 2rem;
  }
  &.xlg {
    font-size: 4rem;
  }
}
